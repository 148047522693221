export const storeLocalStorage = (name, data) => {
    try {
        const json = JSON.stringify(data);
        localStorage.setItem(name, json);
    } catch (error) {
        console.warn('Localstorage Store Data Error!', error);
    }
}

export const retrieveLocalStorage = (name) => {
    try {
        const json = localStorage.getItem(name);
        if (json) return JSON.parse(json);
        return null;
    } catch (error) {
        console.log('Localstorage Retrieve Data Error!', error);
    }
}

export const removeLocalStorageNoJson = (name) => {
    try {
        return localStorage.getItem(name);
    } catch (error) {
        console.log('Localstorage Retrieve Data Error!', error);
    }
}

export const removeLocalStorage = (name) => {
    try {
        localStorage.removeItem(name);
    } catch (error) {
        console.log('Localstorage Remove Data Error!', error);
    }
}