// @ts-nocheck
import React, { useCallback, useState } from "react";
import CommonModal from "../shared_comp/Modal";
import { useLanguageModal } from "../../hooks/useLanguage";

import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import i18next from "i18next";
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
import { removeLocalStorageNoJson } from "../../utils/localStorage";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const Language = () => {
  const { isOpen, onOpen, onClose } = useLanguageModal();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);

  const changeLang = useCallback(
    (type) => {
      i18next.changeLanguage(type);
      setchangeLanguage(type);
      onClose();
    },
    [onClose]
  );

  return (
    <CommonModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onRequestClose={true}
      label="Choose a language"
      onClick={() => {}}
    >
      <div className="d-flex flex-column gap-2">
        <div
          className={`${
            language !== MM ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(MM)}
        >
          <FlagImage width={25} flagImg={MMFlag} />
          <span>ဗမာစာ</span>
        </div>
        <div
          className={`${
            language !== EN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(EN)}
        >
          <FlagImage width={25} flagImg={UKFlag} />
          <span>English</span>
        </div>
        <div
          className={`${
            language !== THAI ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(THAI)}
        >
          <FlagImage width={25} flagImg={ThaiFlag} />
          <span>ไทย</span>
        </div>
        <div
          className={`${
            language !== CN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(CN)}
        >
          <FlagImage width={25} flagImg={ChinaFlag} />
          <span>中国人</span>
        </div>
      </div>
    </CommonModal>
  );
};

export default Language;
