// @ts-nocheck
import React, { useCallback, useContext, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import FooterLink from "../components/shared_comp/FooterLink";

import { BsXDiamond } from "react-icons/bs";
import {
  AiOutlineWallet,
  AiOutlineCustomerService,
  AiOutlineGift,
  AiOutlineSetting,
} from "react-icons/ai";
import { activeUrl } from "../constants/prefixRoutes";
import { useConfettie } from "../hooks/useLanguage";
import { claimPocket } from "../api/promotionApi";
import { LoginContext } from "../store/context/LoginProvider";
import { getProfileApi } from "../api/profileApi";
import { toastError } from "../utils/toast";
import { SET_USER_DATA } from "../store/actions/actionTypes";
import giftbox from "../asset/Gif/giftbox.gif";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [ploading, setPloading] = useState(false);

  const [{ userData }, dispatch] = useContext(LoginContext);
  const isLoggedIn = !!userData?.token;
  const { onConOpen, setShowVD, setPocketAmount } = useConfettie();

  //real amount
  const toggleSync = useCallback(async () => {
    if (!isLoggedIn || ploading) return;

    const promise = new Promise((resolve) => {
      setPloading(true);
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    promise.then(async () => {
      await getProfileApi({ token: userData?.token })
        .then((res) => {
          if (res?.status === 200) {
            dispatch({
              type: SET_USER_DATA,
              payload: { ...userData, balance: res.data?.balance },
            });
          }
        })
        .catch(() => {
          return toastError("Something went wrong!");
        })
        .finally(() => setPloading(false));
    });
  }, [isLoggedIn, ploading, dispatch, userData]);

  const handleGiftBox = useCallback(
    (value) => {
      if (!onConOpen || !setShowVD) return;

      setPocketAmount(value);
      onConOpen();
      setShowVD();
    },
    [onConOpen, setShowVD, setPocketAmount]
  );

  const handlePocketClaim = async () => {
    if (ploading) return;
    setPloading(true);

    await claimPocket({ token: userData?.token })
      .then(async (res) => {
        if (res.status === "success") {
          setPloading(false);

          await toggleSync();
          handleGiftBox(res.amount);
        }
      })
      .catch(async (err) => {
        setPloading(false);

        const error = await err.json();
        if (err.status === 401) {
          return toastError(t("needtologin"));
        }
        toastError(error.message);
      });
  };

  const handleUrl = useCallback(
    (url) => {
      navigate(url);
    },
    [navigate]
  );

  const isActive = useCallback(
    (url) => {
      if (!pathname || !url) return false;
      return activeUrl.includes(pathname) && pathname === url;
    },
    [pathname]
  );

  return (
    <div className="footer-new text-white">
      <FooterLink
        label="home"
        icon={BsXDiamond}
        isActive={isActive(activeUrl[0])}
        handleUrl={(url) => handleUrl(activeUrl[0])}
      />

      <FooterLink
        label="wallet"
        icon={AiOutlineWallet}
        isActive={isActive(activeUrl[1])}
        handleUrl={(url) => handleUrl(activeUrl[1])}
      />

      <FooterLink
        label="gift"
        image={giftbox}
        icon={AiOutlineGift}
        handleUrl={handlePocketClaim}
      />

      <FooterLink
        label="service"
        icon={AiOutlineCustomerService}
        isActive={isActive(activeUrl[2])}
        handleUrl={(url) => handleUrl(activeUrl[2])}
      />

      <FooterLink
        label="setting"
        icon={AiOutlineSetting}
        isActive={isActive(activeUrl[3])}
        handleUrl={(url) => handleUrl(activeUrl[3])}
      />
    </div>
  );
}

export default Footer;
