import { useContext } from "react";
// lib
import { Navigate, useLocation } from 'react-router-dom';
// context
import { LoginContext } from "../../store/context/LoginProvider";
// utils
import { retrieveLocalStorage } from "../../utils/localStorage";

export default function Protected({ children }) {
    // context
    const [{ userData }] = useContext(LoginContext);
    // localStorage
    const userDataLocalStorage = retrieveLocalStorage("user_info");
    // location
    const { pathname: from } = useLocation();
    // Unauthorized ? redirect to login
    if (!userData?.token && !userDataLocalStorage?.token)
        return <Navigate to='/login' replace state={{ from }} />
    else
        return children
}