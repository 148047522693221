// @ts-nocheck
import React, { lazy, Suspense } from "react";
// lib
import { Routes, Route } from "react-router-dom";

import Protected from "../components/shared_comp/Protected";
import FullLoad from "../components/shared_comp/fullLoad";
import NotFound from "../pages/NotFound";

// pages
const Home = lazy(() => import("../pages/Home"));

const OneLottery = lazy(() => import("../pages/OneLottery"));
const TwoLottery = lazy(() => import("../pages/TwoLottery"));
const ThreeLottery = lazy(() => import("../pages/ThreeLottery"));
const CryptoLottery = lazy(() => import("../pages/CryptoLottery"));
const CryptoOneLottery = lazy(() => import("../pages/CryptoOneLottery"));

const Profile = lazy(() => import("../pages/Profile"));
const History = lazy(() => import("../pages/History"));
const SocialLink = lazy(() => import("../pages/SocialLink"));

const Point = lazy(() => import("../pages/Point"));
const Wallet = lazy(() => import("../pages/Wallet"));
const CashIn = lazy(() => import("../pages/CashIn"));
const CashOut = lazy(() => import("../pages/CashOut"));
const Setting = lazy(() => import("../pages/Setting"));

const CashinHistory = lazy(() => import("../pages/Betslip/CashinHistory"));
const CashOutHistory = lazy(() => import("../pages/Betslip/CashOutHistory"));

const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const ForgotPass = lazy(() => import("../pages/ForgotPass"));

const WinNumber = lazy(() => import("../pages/WinNumber"));
const BetSlipDetail = lazy(() => import("../pages/BetSlipDetail"));
const WinnerList = lazy(() => import("../pages/WinnerList"));

const Category = lazy(() => import("../pages/Games/Category"));
const ProviderGame = lazy(() => import("../pages/Games/ProviderGame"));

const PromotionWinnerList = lazy(() => import("../pages/PromotionWinnerList"));

const MainRoute = ({
  socialLink = null,
  provider = [],
  hotLoading = false,
  banner = [],
  marquee,
}) => {
  return (
    <Suspense fallback={<FullLoad />}>
      <Routes>
        {/* Auth Routes */}
        <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/wallet"
          element={
            <Protected>
              <Wallet />
            </Protected>
          }
        />
        <Route
          path="/cashin"
          element={
            <Protected>
              <CashIn />
            </Protected>
          }
        />
        <Route path="/setting" element={<Setting />} />
        <Route
          path="/cashout"
          element={
            <Protected>
              <CashOut />
            </Protected>
          }
        />
        <Route path="/two/lottery" element={<TwoLottery />} />
        <Route path="/three/lottery" element={<ThreeLottery />} />
        <Route path="/crypto/lottery" element={<CryptoLottery />} />
        <Route path="/crypto/one/lottery" element={<CryptoOneLottery />} />
        <Route path="/one/lottery" element={<OneLottery />} />
        <Route
          path="/betslip"
          element={
            <Protected>
              <History />
            </Protected>
          }
        />
        <Route
          path="/game/report/:p_code"
          element={
            <Protected>
              <BetSlipDetail />
            </Protected>
          }
        />
        <Route
          path="/cashin-history"
          element={
            <Protected>
              <CashinHistory />
            </Protected>
          }
        />

        <Route
          path="/cashout-history"
          element={
            <Protected>
              <CashOutHistory />
            </Protected>
          }
        />

        {/* No Auth Routes */}
        <Route
          path="/"
          element={
            <Home
              banner={banner}
              marquee={marquee}
              provider={provider}
              socialLink={socialLink}
              hotLoading={hotLoading}
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/win-number" element={<WinNumber />} />
        <Route path="/winner-list" element={<WinnerList />} />
        <Route path="/pocket-winner" element={<PromotionWinnerList />} />
        <Route path="/:cat" element={<Category />} />
        <Route path="/:cat/:prov" element={<ProviderGame />} />
        <Route path="/forgetpwd" element={<ForgotPass />} />
        <Route path="/point" element={<Point />} />
        <Route
          path="/service"
          element={<SocialLink socialLink={socialLink} />}
        />
        {/* All Games */}
        {/* No Match Routes  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
