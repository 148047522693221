import React from "react";
//comp
import App from "./App";
//provider
import LoginProvider from "./store/context/LoginProvider";

const Global = () => {    
    //make just like redux
    return (
        <LoginProvider>
            <App />
        </LoginProvider>
    );
};

export default Global;
