import React from "react";

function NotFound() {
  return (
    <div className="container-fluid">
      <div
        className="row d-flex align-items-center"
        style={{ height: "500px" }}
      >
        <div className="text-center text-white">
          <h5 className="fw-bold pb-3">404 - PAGE NOT FOUND</h5>
          <small className="text-white text-opacity-75">
            Sorry😞! The page you requested was not found😞.
          </small>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
