export const prefixUrl = [
  "/profile",
  "/login",
  "/register",
  "/forgetpwd",
  "/cashin",
  "/cashout",
  "/one/lottery",
  "/crypto/one/lottery",
  "/crypto/lottery",
  "/two/lottery",
  "/three/lottery",
  "/cashin-history",
  "/cashout-history",
  "/betslip",
  "/win-number",
  "/winner-list",
  "/service",
  "/pocket-winner",
  "/setting",
];

export const activeUrl = ["/", "/wallet", "/service", "/setting"];
