// @ts-nocheck
import * as React from "react";
import CommonModal from "../shared_comp/Modal";

import { useConfettie } from "../../hooks/useLanguage";

import CountUp from "react-countup";

const Confettie = () => {
  const {
    isConOpen,
    onConOpen,
    onConClose,
    isShowVD,
    setShowVD,
    pocketAmount,
  } = useConfettie();

  const [amount, setAmount] = React.useState(pocketAmount);

  React.useEffect(() => setAmount(pocketAmount), [pocketAmount]);

  const handleClose = React.useCallback(() => {
    setShowVD();
    setTimeout(() => onConClose(), 2000);
  }, [onConClose, setShowVD]);

  if (!isShowVD) return null;

  return (
    <CommonModal
      isOpen={isConOpen}
      onOpen={onConOpen}
      onClose={onConClose}
      isFull
    >
      <div className="pkMain d-flex flex-column justify-content-start">
        <div className="w-100 mt-5 text-warning text-center">
          <span className="pkCongrat">Congratulation!</span> <br />
          <div className="mt-2">You have won</div>
          <span className="animate-pk fw-bold mt-5">
            <CountUp
              end={amount}
              duration={5}
              onEnd={() => {
                setTimeout(() => handleClose(), 2000);
              }}
            />{" "}
            <span className="fs-6">ကျပ်</span>
          </span>
        </div>
      </div>
    </CommonModal>
  );
};

export default Confettie;
